/* eslint-disable no-dupe-keys */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	Divider,
	HStack,
	Icon,
	Skeleton,
	Text,
	VStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Center,
	useToast,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FiArrowLeftCircle } from 'react-icons/fi'
import FormFieldList from '../../../components/form/formFieldList'
import {
	useLoadFormFieldList,
	useExcelBotValidationSchema,
	useLoadExcelBotValidation,
	useLoadFormFieldListData,
	useLoadAutopopulateValues,
	useValidateGroupAccountName,
	useValidateGroupAccountCode,
	useSendEmail,
	useUserIdValidation,
	useSendSMS,
	useDedupeBotCuidCheck,
} from '../../../hooks/form.hooks'
import { useAuthContext } from '../../../context/auth.context'
import { useSmartFormContext } from '../../../context/smart-form.context'
import {
	useCreateActivity,
	useSubmitForm,
	useLoadActivityStatusList,
	useLoadActivityTypeList,
	useLoadActivityDetails,
	useLoadWorkforceLevelForms,
	useSubmitDraftForm,
	useAlterDraftForm,
	useArchiveDraftForm,
	useBulkEditForm,
	useLoadChildOrderActivity,
} from '../../../hooks/activity.hooks'
import {
	formatDDMMYYYYHHMM,
	getAddToCurrentDateUTC,
	getCurrentDateUTC,
} from '../../../utils/date.utils'
import {
	accountCode,
	accountFormConfig,
	prepareFinalFieldValueForEdit,
} from '../../../utils/form.util'
import {
	getMessageUniqueId,
	getS3BucketName,
	getUrlId,
	toSnakeCase,
} from '../../../utils/common.util'

import { sharedConst, smartFormConst } from '../../../utils/action.constant'
import { loadFinalValue } from '../../../utils/form.util'
import { useSharedContext } from '../../../context/shared.context'
import FormAddOnFly from '../form-add-onfly'
import UpdateDupName from '../../../components/UpdateDuplicateAccount'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../repository/repo.utils'
import { GA_CATEGORY_FORM_MANAGEMENT } from './../../repository/repo.utils'
import Axios from 'axios'
import { useLanguageContext } from '../../../context/locale.context'

const SmartFormPanel = ({
	formData,
	rateLimit,
	handleError,
	onClose,
	activity,
	isOrigin = false,
	startDate = null,
	endDate = null,
	module = null,
	refillView,
	isOnFly,
	height = 'calc(100vh - 250px)',
	isBulkEdit = false,
	activity_flag_created_by_bot = 0,
	isFromEmail = false,
}) => {
	const toast = useToast({
		duration: 2000,
		position: 'top-right',
	})
	const [fieldList, setfieldList] = useState([])
	const [skipFieldList, setSkipFieldList] = useState([])
	const [checkValidate, setCheckValidate] = useState([])
	const [smartFieldList, setSmartFieldList] = useState([])
	const [errorField, setErrorField] = useState(null)
	const [hFieldName, setHFieldName] = useState('')
	const [fieldDesc, setFieldDesc] = useState('')
	const { mutate, isLoading } = useLoadFormFieldList()
	const { mutate: mutateActivityTypes } = useLoadActivityTypeList()
	const { mutate: mutateActivityStatus } = useLoadActivityStatusList()
	const { mutate: mutateExcelBotalidation } = useLoadExcelBotValidation()
	const { mutate: mutateCreateActivity } = useCreateActivity()
	const { mutate: mutateSubmitForm } = useSubmitForm()
	const { mutate: mutateLoadActivityDetail } = useLoadActivityDetails()
	const { mutate: mutateLoadFieldsData } = useLoadFormFieldListData()
	const { mutate: mutateFormsList } = useLoadWorkforceLevelForms()
	const { mutate: mutateAutoPopulateList } = useLoadAutopopulateValues()
	const { mutate: mutateGroupAccountCode } = useValidateGroupAccountCode()
	const { mutate: mutateGroupAccountName } = useValidateGroupAccountName()
	const { mutate: mutateSubmitDraftForm } = useSubmitDraftForm()
	const { mutate: mutateAlterDraftForm } = useAlterDraftForm()
	const { mutate: mutateArchiveDraftForm } = useArchiveDraftForm()
	const { mutate: mutateBulkEditForm } = useBulkEditForm()
	const { mutate: mutateSendEmail } = useSendEmail()
	const { mutate: userValidationMutate } = useUserIdValidation()
	const { mutate: mutateSendSMS } = useSendSMS()
	const { mutate: loadChildActivityList } = useLoadChildOrderActivity()
	const { mutate: mutateCheckDedupeBot } = useDedupeBotCuidCheck()
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { isOpen: isConfirm, onToggle: onToggleConfirm } = useDisclosure()
	const { isOpen: isInValid, onToggle: onToggleValidate } = useDisclosure()
	const {
		isOpen: isInValidSingleField,
		onToggle: onToggleValidateSingleField,
	} = useDisclosure()
	const { isOpen: isInValidField, onToggle: onToggleValidateField } =
		useDisclosure()
	const {
		isOpen: isDescOpen,
		onOpen: onDescOpen,
		onClose: onDescClose,
	} = useDisclosure()
	const {
		isOpen: isDuplicate,
		onClose: onCloseDuplicate,
		onOpen: openDuplicate,
	} = useDisclosure()
	const {
		isOpen: showUpdateField,
		onClose: onCloseUpdateField,
		onOpen: openUpdateField,
	} = useDisclosure()
	const { asset_flag_super_admin, asset_id } = authData
	const [isSmartForm, setIsSmartForm] = useState(false)
	const [enableSubmit, setEnableSubmit] = useState(false)
	const [showFormAction, setShowFormAction] = useState(false)
	const [accountCheckOn, setaccountCheckOn] = useState(null)
	const [activityType, setActivityType] = useState(null)
	const [activityStatus, setActivityStatus] = useState(null)
	const [excelBotValidationSchema, setExcelBotValidationSchema] = useState()
	const [formOnFly, setFormOnFly] = useState(false)
	const [formDataonFly, setFormData] = useState({})
	const [workforceForms, setWorkforceForms] = useState([])
	const [autoPopuateList, setAutopoplateList] = useState([])
	const [duplicateError, setDuplicateError] = useState('')
	const [refillData, setRefillData] = useState([])

	const {
		state: { fields: formFields, isSmart, deleteFeild },
		dispatch,
	} = useSmartFormContext()

	const fields = !!formFields[formData.form_id]
		? formFields[formData.form_id]
		: {}

	const {
		state: { onFlyDataId },
		dispatch: sharedDispatch,
	} = useSharedContext()
	const formActionRef = useRef()
	const divRef = useRef()

	const scrollToBottom = (f = '', ref = null) => {
		if (formActionRef.current) {
			formActionRef.current.scrollIntoView({
				behavior: 'smooth',
			})
		}
		if (!!f && !isBulkEdit && ref?.current) {
			setTimeout(() => {
				if (ref.current) {
					ref.current.scrollIntoView({
						behavior: 'smooth',
					})
				}
			}, 500)
		}
		if (!!f && !isBulkEdit && authData.organization_flag_enable_grid !== 1) {
			setTimeout(() => {
				if (divRef.current) {
					divRef.current.scrollTo({
						top: divRef.current.scrollHeight,
						behavior: 'smooth',
					})
				}
			}, 500)
		}
	}

	useEffect(() => {
		scrollToBottom()
	}, [fields])

	useEffect(() => {
		if (!!module && !!startDate && !!endDate && fieldList.length > 0) {
			let autoFillFields = fieldList.filter(
				f =>
					typeof f.field_inline_data === 'object' &&
					f.field_inline_data.hasOwnProperty('flag_auto_populate_enabled') &&
					f.field_inline_data.flag_auto_populate_enabled === 1
			)
			autoFillFields.map(async i => {
				let value =
					i.data_type_id === 77
						? {
								start_date_time: new Date(startDate),
								duration: '0',
								end_date_time: new Date(endDate),
								isFromEdit: true,
						  }
						: new Date(startDate)
				await dispatch({
					type: smartFormConst.SET_FIELD_VALUE,
					fieldId: i.field_id,
					formId: i.form_id,
					value: loadFinalValue(i, value),
				})
			})
		}
		if (fieldList.length > 0 && autoPopuateList.length > 0) {
			fieldList.map(item => {
				let fieldItem = autoPopuateList.find(value => !!value[item.field_id])
				if (!!fieldItem) {
					let fieldValue = {
						...item,
						field_data_type_id: item.data_type_id,
						field_value: fieldItem[item.field_id],
					}
					dispatch({
						type: smartFormConst.SET_FIELD_VALUE,
						fieldId: item.field_id,
						formId: item.form_id,
						value: loadFinalValue(
							item,
							prepareFinalFieldValueForEdit(fieldValue, item)
						),
					})
				}
			})
		}
		if (
			fieldList.length > 0 &&
			!!formData.isDraft &&
			!!formData.draftFormTransId
		) {
			let skipFieldsCheck = fieldList.map(item => {
				let fieldItem = formData.form_draft_inline_data.find(
					d => item.field_id === d.field_id
				)
				if (!!fieldItem) {
					let fieldValue = {
						...item,
						data_type_combo_id: fieldItem.data_type_combo_id,
						field_data_type_id: item.data_type_id,
						field_value: fieldItem.field_value,
					}
					dispatch({
						type: smartFormConst.SET_FIELD_VALUE,
						fieldId: item.field_id,
						formId: item.form_id,
						value: loadFinalValue(
							item,
							prepareFinalFieldValueForEdit(fieldValue, item)
						),
					})
					let obj = {}
					obj['field_id'] = item.field_id
					obj[item.field_id] = loadFinalValue(
						item,
						prepareFinalFieldValueForEdit(fieldValue, item)
					)
					return obj
				}
			})
			let formObj = {}
			formObj[formData.form_id] = {}
			skipFieldsCheck
				.filter(k => k)
				.forEach((k, i) => {
					formObj[formData.form_id][k.field_id] = k[k.field_id]
				})
			setRefillData(formObj)
			setSkipFieldList(skipFieldsCheck.filter(k => k).map(s => s.field_id))
		}
	}, [startDate, endDate, module, fieldList, autoPopuateList])

	const RenderNextField = async (obj, list = [], data = []) => {
		let nxt
		if (obj.data_type_id === 33) {
			let comboId = obj.data?.field_value?.comboNextFieldId
			if (comboId) {
				nxt = data.find(f => f.field_id === comboId)
			} else {
				nxt = data.find(f => f.field_id === obj.next_field_id)
			}
		} else {
			nxt = data.find(f => f.field_id === obj.next_field_id)
		}
		if (nxt) {
			list.push(nxt)
			if (nxt.data_type_id === 33) {
				let comboId = nxt.data?.field_value?.comboNextFieldId
				let combo
				if (comboId) {
					combo = data.find(f => f.field_id === comboId)
				} else {
					combo = data.find(f => f.field_id === nxt.next_field_id)
				}
				if (combo) {
					list.push(combo)
					await RenderNextField(combo, list, data)
				}
			} else {
				await RenderNextField(nxt, list, data)
			}
		}
		return list
	}

	useEffect(() => {
		if (refillView) {
			mutateLoadFieldsData(
				{
					fieldList: {
						organization_id:
							formData.organization_id || authData.organization_id,
						account_id: formData.account_id || authData.account_id,
						workforce_id: formData.workforce_id || authData.workforce_id,
						form_id: formData.form_id,
					},
					fieldValues: {
						form_id: formData.form_id,
						form_transaction_id: formData.form_transaction_id
							? formData.form_transaction_id
							: formData.data_form_transaction_id,
						start_from: 0,
						limit_value: 50,
					},
				},
				{
					onSuccess: async data => {
						const isSmart = !!data.map(i => i.next_field_id).filter(Boolean)
							.length
						setIsSmartForm(isSmart)
						let rawFields = []
						let smartFields = [data[0]]

						if (!isSmart) {
							rawFields = data.filter(f => !!f.data)
							// rawFields = data
							// 	.map(i => (!!i.field_mandatory_enabled ? i : null))
							// 	.filter(Boolean)
							setfieldList(rawFields)
						} else {
							let nxt_field = await RenderNextField(data[0], smartFields, data)
							rawFields = nxt_field
							let skipped_fields = data.filter(
								d => !nxt_field.map(n => n.field_id).includes(d.field_id)
							)
							//setfieldList([...rawFields, ...skipped_fields])
							let skipped_fields_nxt_fid =
								!!skipped_fields && skipped_fields.length
									? skipped_fields.filter(f => f.next_field_id !== 0)
									: []
							//setfieldList([...rawFields, ...skipped_fields])
							setfieldList([...rawFields, ...skipped_fields_nxt_fid]) //not including fields which are not part of smart loop in smart form(in refill view)
							// setfieldList([...rawFields]) //not including fields which are not part of smart loop in smart form(in refill view)
						}
						let fields = {}
						fields[formData.form_id] = {}
						rawFields.forEach(item => {
							if (!!item.data) {
								fields[formData.form_id][`${item.field_id}`] = {
									...item.data,
									field_mandatory_enabled: item.field_mandatory_enabled,
								}
							}
							// else {
							//   fields[formData.form_id][`${item.field_id}`] = {
							//     ...item,
							//     field_mandatory_enabled: item.field_mandatory_enabled,
							//   }
							// } // Do not enable, Crash in refill if empty data
						})
						setRefillData(fields)
						dispatch({
							type: smartFormConst.SET_FIELD_VALUES,
							formId: formData.form_id,
							fields,
						})
					},
					onError: async err => {
						console.log('error occurred while loading fields', err)
					},
				}
			)
		} else {
			mutate(
				{
					organization_id: formData.organization_id || authData.organization_id,
					account_id: formData.account_id || authData.account_id,
					workforce_id: formData.workforce_id || authData.workforce_id,
					form_id: formData.form_id,
				},
				{
					onSuccess: async data => {
						if (data) {
							const isSmart = data
								.map(i => i.next_field_id)
								.filter(Boolean).length
							let botEnabledFields = data
								.filter(f => f.field_validation_enabled === 1)
								.map(botfield => ({
									form_id: botfield.form_id,
									field_id: botfield.field_id,
									field_validation_enabled: botfield.field_validation_enabled,
								}))
							if (botEnabledFields.length > 0) {
								onExcelBotValidation({
									botEnabledFields,
									organization_id: formData.organization_id,
									account_id: formData.account_id,
									workforce_id: formData.workforce_id,
									form_id: formData.form_id,
								})
							}

							setIsSmartForm(isSmart)
							let rawFields = data
							if (isFromEmail) {
								if (
									!isSmart &&
									!(formData?.form_flag_enable_external_link === 2)
								) {
									rawFields = data
										.map(i => (!!i.field_mandatory_enabled ? i : null))
										.filter(Boolean)
								}
							}
							if (!isSmart && !isFromEmail) {
								rawFields = data
									.map(i => (!!i.field_mandatory_enabled ? i : null))
									.filter(Boolean)
							}
							setfieldList(rawFields)
						}
					},
					onError: async err => {
						console.log('Error Occurred On field List', err)
						handleError()
					},
				}
			)
		}
		mutateFormsList(
			{},
			{
				onSuccess: async data => {
					if (!!data && data.list?.length > 0) {
						setWorkforceForms(data)
					}
				},
				onError: async err => {
					console.log('err', err)
				},
			}
		)
		mutateActivityTypes(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityType(data)
				},
			}
		)
		mutateActivityStatus(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityStatus(data)
				},
			}
		)
		if (
			formData.form_flag_prefill_enabled === 1 &&
			!formData?.isSubmitted // to stop prefilling data once the form is submitted and has updated values
		) {
			mutateAutoPopulateList(
				{
					form_id: formData.form_id,
					//workflow_activity_id: activity.activity_id,
					workflow_activity_id: formData.activity_id,
				},
				{
					onSuccess: async data => {
						setAutopoplateList(data)
					},
					onError: async err => {
						console.log('err', err)
					},
				}
			)
		}
		let orgConfig = accountFormConfig[formData.organization_id]
		if (orgConfig) {
			const pId =
				formData.form_workflow_activity_type_id || formData.activity_type_id
			const nameConfig = orgConfig.accountNameConfig[pId]
			const codeConfig = orgConfig.accountCodeConfig[pId]
			if (!!nameConfig && nameConfig.form_id === formData.form_id) {
				setaccountCheckOn(accountCode.ACCOUNT_NAME)
			} else if (
				(!!codeConfig &&
					Array.isArray(codeConfig.form_id) &&
					codeConfig.form_id.includes(formData.form_id)) ||
				(codeConfig && codeConfig.form_id === formData.form_id)
			) {
				setaccountCheckOn(accountCode.ACCOUNT_CODE)
			} else {
				setaccountCheckOn(null)
			}
		}
	}, []) //fix for refresh issue

	const onExcelBotValidation = params => {
		mutateExcelBotalidation(params, {
			onSuccess: async data => {
				let botOperationInline = data
				if (!!botOperationInline) {
					setExcelBotValidationSchema(botOperationInline)
				}
			},
		})
	}

	const participantInfo = () => {
		const {
			account_id,
			asset_first_name,
			asset_id,
			asset_image_path,
			asset_last_name,
			asset_phone_number,
			asset_phone_number_code,
			field_id,
			operating_asset_first_name,
			organization_id,
			workforce_id,
		} = authData
		return [
			{
				access_role_id: 22,
				asset_datetime_last_seen: '1970-01-01 00:00:00',
				activity_id: formData.activity_id,
				asset_type_category_id: 3,
				asset_type_id: 133001,
				field_id: 0,
				account_id,
				asset_first_name,
				asset_id,
				asset_image_path,
				asset_last_name,
				asset_phone_number,
				asset_phone_number_code,
				log_asset_id: asset_id,
				message_unique_id: getMessageUniqueId(),
				operating_asset_first_name,
				organization_id,
				workforce_id,
			},
		]
	}

	const timelineInline = formFieldsData => {
		let content = 'Form Submitted'
		let body = formData.form_name
		return {
			mail_body: body,
			asset_reference: [
				{
					account_id: '',
					organization: '',
					asset_id: '',
					asset_first_name: '',
					asset_type_category_id: '',
					asset_last_name: '',
					asset_image_path: '',
				},
			],
			activity_reference: [
				{
					activity_title: '',
					activity_id: '',
				},
			],
			form_approval_field_reference: [],
			subject: body,
			attachments: [],
			content: content,
			form_id: formData.form_id,
			form_submitted: formFieldsData,
			form_flag_enable_data_secrecy: formData?.form_flag_enable_data_secrecy,
		}
	}

	const prepareFormSaveParams = (resData = null) => {
		const { data_type_id, field_id } = fieldList[0]
		const {
			activity_id,
			wf_due_date_days,
			form_id,
			form_workflow_activity_type_category_id: activity_type_category_id,
			form_workflow_activity_type_id,
			form_workflow_activity_type_default_duration_days,
		} = formData
		// console.log('form data', formData)
		const { activity_type_id } = activityType
		const { activity_status_id } = activityStatus
		let title = `${formatDDMMYYYYHHMM()}-${formData.form_name}`
		if (data_type_id === 65) {
			// console.log('fields[field_id]', fields[field_id])
			const parseValue = fields[field_id].field_value
			title = parseValue.contact_name
		} else if (data_type_id === 57) {
			let formTitle = fields[field_id].field_value.label
			title = !!formTitle ? formTitle : title
		} else if (!![19, 20].includes(data_type_id)) {
			title = !!fields[field_id] ? fields[field_id].field_value : title
		}

		let formFieldsData = Object.values(fields)
			.filter(f => !f.is_skip)
			.map(i => ({
				final: i.final,
				field_sequence_id: fieldList.find(f => f.field_id === i.final.field_id)
					?.field_sequence_id,
			}))
			.sort((a, b) => (a.field_sequence_id > b.field_sequence_id ? 1 : -1))
			.map(i => i.final)
			.filter(Boolean)
		// removing deleted image value
		const targetObject = formFieldsData.find(
			item => item.field_id === deleteFeild?.field_id
		)

		if (targetObject) {
			targetObject.field_value = ''
		}

		// let formFieldsData = Object.values(fields)
		//   .filter(f => !f.is_skip)
		//   .map(i => i.final)
		//   .filter(Boolean)

		return {
			activity_id: !!activity_id ? activity_id : 0,
			channel_activity_id: !!activity_id ? activity_id : 0,
			activity_title: title,
			activity_description: title,
			activity_inline_data: JSON.stringify(formFieldsData),
			// data_entity_inline: JSON.stringify(formFieldsData),
			activity_timeline_collection: JSON.stringify(
				timelineInline(formFieldsData)
			),
			activity_participant_collection: JSON.stringify(participantInfo()),
			form_id: form_id,
			activity_form_id: form_id,
			workflow_activity_id: !!activity_id ? activity_id : 0,
			activity_type_id: activity_type_id || form_workflow_activity_type_id,
			is_refill: refillView ? 1 : 0,
			form_workflow_activity_type_id,
			// activity_type_id: isOrigin
			//   ? form_workflow_activity_type_id
			//   : activity_type_id,
			generated_group_account_name:
				!!resData && !!resData.generated_group_account_name
					? resData.generated_group_account_name
					: null, // this should has to come from account name validation
			generated_account_code:
				!!resData && !!resData.generated_account_code
					? resData.generated_account_code
					: null, // this should has to come from account code validation
			activity_title_expression:
				!!resData && !!resData.activity_title_expression
					? resData.activity_title_expression
					: null, // this should has to come from account code validation
			gst_number: !!resData && !!resData.gst_number ? resData.gst_number : '', // this should has to come from account code validation
			pan_number: !!resData && !!resData.pan_number ? resData.pan_number : '', // this should has to come from account code validation
			activity_datetime_end:
				activity_id === 0
					? getAddToCurrentDateUTC(
							getCurrentDateUTC(),
							form_workflow_activity_type_default_duration_days
					  )
					: getCurrentDateUTC(), //utils.getCurrentDateUTC(),
			activity_datetime_start: getCurrentDateUTC(),
			activity_status_id,
			activity_form_id: formData.form_id,
			form_id: formData.form_id,
			workflow_activity_id: formData.activity_id,
			lead_asset_first_name: !!activity
				? activity.activity_lead_asset_first_name
				: '',
			lead_asset_type_id:
				!!activity && activity.activity_lead_asset_type_id
					? activity.activity_lead_asset_type_id
					: null,
			lead_asset_id: !!activity ? activity.activity_lead_asset_id : '',
		}
	}

	const prepareBulkEditParams = () => {
		const { data_type_id, field_id } = fieldList[0]
		const {
			activity_id,
			wf_due_date_days,
			form_id,
			form_workflow_activity_type_category_id: activity_type_category_id,
			form_workflow_activity_type_id,
			form_workflow_activity_type_default_duration_days,
		} = formData

		const { activity_type_id } = activityType
		const { activity_status_id } = activityStatus
		let title = `${formatDDMMYYYYHHMM()}-${formData.form_name}`
		if (data_type_id === 65) {
			const parseValue = fields[field_id].field_value
			title = parseValue.contact_name
		} else if (data_type_id === 57) {
			let formTitle = fields[field_id].field_value.label
			title = !!formTitle ? formTitle : title
		} else if (!![19, 20].includes(data_type_id)) {
			title = !!fields[field_id] ? fields[field_id].field_value : title
		}

		let formFieldsData = Object.values(fields)
			.filter(f => !f.is_skip)
			.map(i => i.final)
			.filter(Boolean)

		const targetObject = formFieldsData.find(
			item => item.field_id === deleteFeild?.field_id
		)

		if (targetObject) {
			targetObject.field_value = ''
		}

		return {
			activity_id: !!activity_id ? activity_id : 0,
			activity_title: title,
			activity_inline_data: JSON.stringify(formFieldsData),
			form_id: form_id,
			activity_form_id: form_id,
			workflow_activity_id: !!activity_id ? activity_id : 0,
			activity_type_id,
			form_workflow_activity_type_id,
			activity_status_id,
			activity_form_id: formData.form_id,
			form_id: formData.form_id,
			form_transaction_id: formData.form_transaction_id,
			workflow_activity_id: formData.activity_id,
			activity_type_category_id: 9,
			message_unique_id: getMessageUniqueId(),
			track_latitude: 0,
			track_longitude: 0,
			track_gps_accuracy: 0,
			track_gps_status: 0,
			track_gps_location: '',
			track_gps_datetime: getCurrentDateUTC(),
			device_os_id: 5,
		}
	}

	const prepareDraftSaveParams = () => {
		const { data_type_id, field_id } = fieldList[0]
		const { activity_id } = formData
		const { operating_asset_id } = activity

		let title = `${formatDDMMYYYYHHMM()}-${formData.form_name}`
		if (data_type_id === 65) {
			const parseValue = fields[field_id].field_value
			title = parseValue.contact_name
		} else if (data_type_id === 57) {
			let formTitle = fields[field_id].field_value.label
			title = !!formTitle ? formTitle : title
		} else if (!![19, 20].includes(data_type_id)) {
			title = !!fields[field_id] ? fields[field_id].field_value : title
		}

		let formFieldsData = Object.values(fields)
			.map(i => i.final)
			.filter(Boolean)

		return {
			form_draft_inline_data: JSON.stringify(formFieldsData),
			form_draft_title: `Draft - ${title}`,
			form_id: formData.form_id,
			operating_asset_id: !!operating_asset_id ? operating_asset_id : 0,
			workflow_activity_id: !!activity_id ? activity_id : 0,
			device_os_id: 5,
		}
	}

	const prepareDraftAlterParams = () => {
		const { data_type_id, field_id } = fieldList[0]
		const { activity_id } = formData
		const { operating_asset_id } = activity

		let title = `${formatDDMMYYYYHHMM()}-${formData.form_name}`
		if (data_type_id === 65) {
			const parseValue = fields[field_id].field_value
			title = parseValue.contact_name
		} else if (data_type_id === 57) {
			let formTitle = fields[field_id].field_value.label
			title = !!formTitle ? formTitle : title
		} else if (!![19, 20].includes(data_type_id)) {
			title = !!fields[field_id] ? fields[field_id].field_value : title
		}

		let formFieldsData = Object.values(fields)
			.map(i => i.final)
			.filter(Boolean)

		return {
			form_id: formData.form_id,
			form_transaction_id: formData.draftFormTransId,
			form_inline_data: JSON.stringify(formFieldsData),
		}
	}

	const loadDetails = useCallback(activity => {
		mutateLoadActivityDetail(
			{
				activity_id: activity.activity_id,
				activity_type_id: activity.activity_type_id,
			},
			{
				onSuccess: async details => {
					const isLead = asset_id === details.activity_lead_asset_id
					const isOwner = asset_flag_super_admin
					sharedDispatch({
						type: sharedConst.ACTVITY_SELECTED,
						payload: {
							...details,
							isLead,
							isOwner,
						},
					})
					if (!!activity.data_type_id) {
						sharedDispatch({
							type: sharedConst.FORM_ONFLY_ADD_DATA,
							payload: {
								...details,
							},
						})
					}
					onClose(details)
				},
				onError: async err => {
					console.log('Err occurred', err)
				},
			}
		)
	})

	const formAddOnFly = form => {
		setFormOnFly(true)
		setFormData(form)
		if (!!form.data_type_id) {
			sharedDispatch({
				type: sharedConst.FORM_ONFLY_DATA_TYPE_ID,
				payload: form.data_type_id,
			})
			sharedDispatch({
				type: sharedConst.FORM_ONFLY_FIELD_ID,
				payload: form.field_id,
			})
		}
	}

	const submitForm = (resData = null) => {
		mutateCreateActivity(
			{
				...prepareFormSaveParams(resData),
				isOrigin,
				form_api_activity_type_category_id:
					formData.form_workflow_activity_type_category_id || 48,
				form_api_activity_type_id: formData.form_workflow_activity_type_id,
				activity_flag_created_by_bot: activity_flag_created_by_bot,
			},
			{
				onSuccess: async data => {
					if (!formOnFly || !!onFlyDataId) {
						loadDetails({
							...data,
							activity_type_id: formData.form_workflow_activity_type_id,
							data_type_id: onFlyDataId,
						})
					}
				},
				onError: async err => {
					console.log('Error', err)
				},
			}
		)
	}

	const createTimeLineParams = async data => {
		await getURL(data)
	}

	const createDynamicLink = async url => {
		return await Axios.post(
			'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAWSBRYMxdKQlCl4TNJymMEmve3kPFsrdI',
			{
				dynamicLinkInfo: {
					domainUriPrefix: 'https://greneos.page.link',
					link: url,
					navigationInfo: {
						enableForcedRedirect: true,
					},
				},
				suffix: {
					option: 'SHORT',
				},
			}
		)
	}

	const createFormUpdateUrlParam = activity => {
		return {
			organization_id: authData.organization_id,
			account_id: authData.account_id,
			workforce_id: authData.workforce_id,
			asset_id: authData.asset_id,
			auth_asset_id: authData.asset_id,
			asset_token_auth: authData.asset_token_auth, //"05986bb0-e364-11e8-a1c0-0b6831833754",
			activity_id: !!activity.activity_id
				? activity.activity_id
				: formData.activity_id,
			activity_type_category_id:
				formData.form_workflow_activity_type_category_id || 48,
			form_transaction_id: !!activity.form_transaction_id
				? activity.form_transaction_id
				: formData.form_transaction_id,
			activity_stream_type_id: 705,
			form_id: formData.form_id,
			form_flag_prefill_enabled: 0,
			activity_type_id:
				formData.form_workflow_activity_type_id || formData.activity_type_id,
			activity_type_id_form:
				formData.form_workflow_activity_type_id || formData.activity_type_id,
			type: 'approval',
			asset_first_name: authData.asset_first_name,
			asset_phone_number: authData.asset_phone_number,
			operating_asset_first_name: authData.operating_asset_first_name,
			s3BucketName: getS3BucketName(),
			activity_type_welcome_message: 'Welcome',
			activity_type_closing_message: 'Alert',
			form_workflow_activity_type_id:
				formData.form_workflow_activity_type_id || formData.activity_type_id,
		}
	}

	const getURL = async (e = {}) => {
		const updatePayload = createFormUpdateUrlParam(e)
		let data = await getUrlId([], updatePayload)
		let url = process.env.REACT_APP_EXT_URL
		let webUrl = `${url}originform/formpreview/${btoa(JSON.stringify(data))}`
		const res = await createDynamicLink(webUrl)
		const { activity_master_data } = activity
		const activity_timeline_url = !!activity_master_data
			? JSON.parse(activity_master_data)?.activity_timeline_url
			: ''
		if (!!res.data.shortLink)
			await sendSMSHandler(activity_timeline_url, res.data.shortLink)
	}

	const sendSMSHandler = async (timeline_url = '', update_url = '') => {
		mutateSendSMS(
			{
				message: `Dear ${authData.operating_asset_first_name}, a new update - Form has been added to your request - ${formData.form_name}. Please tap on the below link to view the update ${update_url}. Please tap on the below link to access the timeline of your request ${timeline_url}. Greneos.`,
				country_code: `${authData.asset_phone_country_code}`,
				phone_number: `${authData.asset_phone_number}`,
				organization_id: `${authData.organization_id}`,
			},
			{
				onSuccess: async data => {
					if (data === 200) {
						alert('SMS sent successfully!')
					}
				},
			}
		)
	}

	const sendMailParams = async (timeline_url = '', update_url = '') => {
		return {
			asset_id: authData.asset_id,
			organization_id: authData.organization_id,
			asset_token_auth: authData.asset_token_auth,
			customer_name: authData.operating_asset_first_name,
			email_subject: formData.form_name,
			email_body: formData.form_name,
			email_receivers: [authData.asset_email_id],
			email_sender_name: 'GreneOS',
			outlook_email_integration: 0,
			email_sender: 'support@greneos.com',
			flag: 0,
			email_sender_username: '',
			email_sender_asset_id: authData.asset_id,
			html_template: btoa(`
        <p>
        Dear <b>${authData?.operating_asset_first_name}, </b> a new update - <b>${formData.form_name}</b> has been added to your request - <b>${activity.activity_title}</b>. 
        <br/>Please tap on the below link to view the update 
        <a
        style="color: 'blue'"
        href="${update_url}"
        >
        View Updates
        </a><br/>
        Please tap on the below link to access the timeline of your request 
        <a
        style="color: 'blue'"
        href="${timeline_url}"
        >
        View Timeline
        </a></p>
    `),
		}
	}

	const sendMailHandler = async (timeline, update) => {
		const payload = await sendMailParams(timeline, update)
		if (payload) {
			sendEmailv8(payload)
		}
	}

	const sendEmailv8 = payload => {
		mutateSendEmail(
			{
				payload,
			},
			{
				onSuccess: async data => {
					if (data.status) {
						alert('E-mail sent successfully!')
					}
				},
			}
		)
	}

	const submitNonOriginForm = (resData = null) => {
		mutateSubmitForm(
			{
				...prepareFormSaveParams(resData),
				isOrigin,
				form_api_activity_type_category_id:
					formData.form_workflow_activity_type_category_id || 48,
				form_api_activity_type_id: formData.form_workflow_activity_type_id,
			},
			{
				onSuccess: async data => {
					console.log('data', data)
					if (!!activity && activity?.activity_flag_created_by_bot === 2) {
						await createTimeLineParams(data)
					}
					// FIXME: Refresh timeline and load activity again to fix refresh issu
					await onClose(data)
				},
				onError: async err => {
					console.log('Error', err)
				},
			}
		)
	}

	const validateSaveDraft = () => {
		let valid = true
		let cValid

		/*
		 * If grid is enabled, even if one mandatory field has valid data, save draft should be allowed,
		 * according to business requirements.
		 */
		if (authData.organization_flag_enable_grid === 1) {
			let requiredFields = []
			smartFieldList.forEach(f => {
				let isskipEnabled = isSkipConfig(f)
				if (f.field_mandatory_enabled === 1 && !isskipEnabled) {
					if (!!fields[f.field_id]) {
						if (typeof fields[f.field_id].final.field_value === 'string') {
							requiredFields.push({
								field_id: f.field_id,
								field_name: f.field_name,
								valid: !!fields[f.field_id].final.field_value.trim(),
							})
						} else {
							requiredFields.push({
								field_id: f.field_id,
								field_name: f.field_name,
								valid: !!fields[f.field_id].final.field_value,
							})
						}
					} else {
						requiredFields.push({
							field_id: f.field_id,
							field_name: f.field_name,
							valid: false,
						})
					}
				}
			})
			cValid = checkValidate
				.map(c => {
					if (requiredFields.map(v => v.field_id).includes(c.field_id)) return c
				})
				.filter(Boolean)
			if (requiredFields.every(s => s.valid === false)) {
				onToggleValidateSingleField()
				setEnableSubmit(false)
				valid = false
			} else if (cValid.some(v => v.valid === false)) {
				onToggleValidateField()
				setErrorField(cValid.filter(f => f.valid === false)?.[0]?.field_name)
				setEnableSubmit(false)
				valid = false
			}
		} else {
			let validate = smartFieldList.map(f => {
				let isskipEnabled = isSkipConfig(f)
				return f?.field_mandatory_enabled === 1 && !isskipEnabled
					? !!fields[f.field_id]
						? typeof fields[f.field_id]?.final?.field_value === 'string'
							? {
									field_id: f.field_id,
									field_name: f.field_name,
									valid: !!fields[f.field_id]?.final?.field_value.trim(),
							  }
							: {
									field_id: f.field_id,
									field_name: f.field_name,
									valid: !!fields[f.field_id]?.final?.field_value,
							  }
						: { field_id: f.field_id, field_name: f.field_name, valid: false }
					: { field_id: f.field_id, field_name: f.field_name, valid: true }
			})

			cValid = checkValidate
				.map(c => {
					if (validate.map(v => v.field_id).includes(c.field_id)) return c
				})
				.filter(Boolean)
			if (validate.some(s => s.valid === false)) {
				onToggleValidate()
				setEnableSubmit(false)
				setErrorField(validate.filter(f => f.valid === false)?.[0]?.field_name)
				valid = false
			} else if (cValid.some(v => v.valid === false)) {
				onToggleValidateField()
				setErrorField(cValid.filter(f => f.valid === false)?.[0]?.field_name)
				setEnableSubmit(false)
				valid = false
			}
		}
		return valid
	}

	const onSaveDraft = () => {
		setEnableSubmit(true)

		if (validateSaveDraft()) {
			if (!!formData.isDraft && !!formData.draftFormTransId) {
				mutateAlterDraftForm(
					{
						...prepareDraftAlterParams(),
					},
					{
						onSuccess: async data => {
							console.log('data', data)
							toast({
								title: 'Form saved as draft!',
								status: 'success',
							})
							// FIXME: Refresh timeline and load activity again to fix refresh issu
							onClose(data)
						},
						onError: async err => {
							console.log('Error', err)
						},
					}
				)
			} else {
				mutateSubmitDraftForm(
					{
						...prepareDraftSaveParams(),
					},
					{
						onSuccess: async data => {
							console.log('data', data)
							// FIXME: Refresh timeline and load activity again to fix refresh issu
							toast({
								title: 'Form saved as draft!',
								status: 'success',
							})
							onClose(data)
						},
						onError: async err => {
							console.log('Error', err)
						},
					}
				)
			}
		}
	}

	const isSkipConfig = field => {
		if (field?.field_inline_data?.field_skip_condition) {
			let { data_type_combo_id, field_id } =
				field.field_inline_data.field_skip_condition
			let skipComboId =
				!!fields && !!fields[field_id]
					? fields[field_id]?.final?.data_type_combo_id
					: null
			if (data_type_combo_id === skipComboId) {
				return true
			}
			return false
		}
		return [21, 17].includes(field.data_type_id)
	}

	const validateService = async (value, fieldId = []) => {
		return await new Promise(resolve => {
			userValidationMutate(
				{
					activity_inline_data: value,
					field_id: fieldId.join(' '),
				},
				{
					onSuccess: async data => {
						resolve(data)
					},
				}
			)
		})
	}

	const toReturnChildData = async () => {
		return new Promise((resolve, reject) => {
			loadChildActivityList(
				{
					parent_activity_id: activity.activity_id,
					page_start: 0,
					page_limit: 500,
					startFrom: 0,
					sort_flag: 0,
					flag: 1,
					// flag: 3,
					datetime_end: getCurrentDateUTC(),
					datetime_start: '1970-01-01 00:00:00',
				},
				{
					onSuccess: async data => {
						console.log(data.list, 'res data')
						resolve(data?.list || [])
					},
					onError: err => {
						reject(err)
					},
				}
			)
		})
	}
	const dedupeCheck = async () => {
		let newFormInlineArray = Object.values(fields)
			.map(i => i.final)
			.filter(Boolean)
		const processId =
			formData.form_workflow_activity_type_id || formData.activity_type_id
		const dedupeCheckParams = {
			activity_type_category_id:
				formData.form_workflow_activity_type_category_id,
			activity_type_id: processId,
			activity_inline_data: JSON.stringify(newFormInlineArray),
		}
		return new Promise((resolve, reject) => {
			mutateCheckDedupeBot(dedupeCheckParams, {
				onSuccess: async data => {
					console.log(data, 'dedupedata')
					resolve(data?.cuid_available)
				},
				onError: err => {
					reject(err)
				},
			})
		})
	}

	const onSave = async () => {
		if (
			!!formData?.form_flag_enable_check_open_child_workflows &&
			activity?.activity_sub_type_id === 1
		) {
			const childData = await toReturnChildData()
			if (childData.length > 0) {
				alert('Please close all child orders before closing Parent Order!!')
				return
			}
		}

		if (!!isOrigin && !!formData?.form_flag_dedupe_check_enabled) {
			const checkCuid = await dedupeCheck()
			if (checkCuid) {
				alert(
					'A workflow exists that matches the deduplication criteria. Please check !!'
				)
				return
			}
		}

		setEnableSubmit(true)
		onCloseUpdateField()
		let validate = smartFieldList.map(f => {
			let isskipEnabled = isSkipConfig(f)
			return f?.field_mandatory_enabled === 1 && !isskipEnabled
				? !!fields[f.field_id]
					? typeof fields[f.field_id]?.final?.field_value === 'string'
						? {
								field_id: f.field_id,
								field_name: f.field_name,
								valid: !!fields[f.field_id]?.final?.field_value.trim(),
						  }
						: {
								field_id: f.field_id,
								field_name: f.field_name,
								valid: !!fields[f.field_id]?.final?.field_value,
						  }
					: { field_id: f.field_id, field_name: f.field_name, valid: false }
				: { field_id: f.field_id, field_name: f.field_name, valid: true }
		})
		let userValidation = smartFieldList
			.map(f => {
				if (f?.field_mandatory_enabled === 1) {
					if (
						!!f.field_inline_data &&
						f.field_inline_data.flag_validation_enabled === 1 &&
						!!f.field_inline_data.ethernet_url
					) {
						return f.field_id
					}
				}
			})
			.filter(Boolean)
		let formFieldsData = Object.values(fields)
			.filter(f => userValidation.includes(f.final.field_id))
			.map(i => {
				return i.final
			})
			.filter(Boolean)
		let resp = []
		if (!!formFieldsData && formFieldsData.length) {
			resp = await validateService(
				JSON.stringify(formFieldsData),
				userValidation
			)
		}
		if (!!resp && resp.length) {
			if (resp[0]?.message !== 'User id available') {
				toast({
					title: resp[0]?.message,
					status: 'error',
				})
				setEnableSubmit(false)
				return false
			}
		}
		const cValid = checkValidate
			.map(c => {
				if (validate.map(v => v.field_id).includes(c.field_id)) return c
			})
			.filter(Boolean)
		if (validate.some(s => s.valid === false)) {
			onToggleValidate()
			setErrorField(validate.filter(f => f.valid === false)?.[0]?.field_name)
			setEnableSubmit(false)
		} else if (cValid.some(v => v.valid === false)) {
			onToggleValidateField()
			setErrorField(cValid.filter(f => f.valid === false)?.[0]?.field_name)
			setEnableSubmit(false)
		} else {
			if (!!isBulkEdit) {
				const { form_id } = fieldList[0]
				let validate = Object.keys(refillData[form_id]).map(f => {
					let isskipEnabled = isSkipConfig(refillData[form_id][f])
					//return refillData[form_id][f]?.field_mandatory_enabled === 1 &&
					return refillData[form_id][f] && !isskipEnabled
						? !!fields[f]
							? typeof fields[f]?.final?.field_value === 'string'
								? {
										field_id: refillData[form_id][f]?.final?.field_id,
										field_name: refillData[form_id][f]?.final?.field_name,
										valid: !!fields[f]?.final?.field_value.trim(),
								  }
								: {
										field_id: refillData[form_id][f]?.final?.field_id,
										field_name: refillData[form_id][f]?.final?.field_name,
										valid: !!fields[f]?.final?.field_value,
								  }
							: {
									field_id: refillData[form_id][f]?.final?.field_id,
									field_name: refillData[form_id][f]?.final?.field_name,
									valid: false,
							  }
						: {
								field_id: refillData[form_id][f]?.final?.field_id,
								field_name: refillData[form_id][f]?.final?.field_name,
								valid: true,
						  }
				})
				console.log('validate...', validate)
				if (validate.some(s => s.valid === false)) {
					onToggleValidate()
					setErrorField(
						validate.filter(f => f.valid === false)?.[0]?.field_name
					)
				} else {
					const bulkEditParams = prepareBulkEditParams()
					mutateBulkEditForm(
						{
							...bulkEditParams,
						},
						{
							onSuccess: async data => {
								console.log('Bulk edit data5', data)
								onClose(data)
							},
							onError: async err => {
								console.log('Error', err)
							},
						}
					)
				}
			} else {
				if (!!formData.isDraft && !!formData.draftFormTransId) {
					mutateArchiveDraftForm(
						{
							form_id: formData.form_id,
							form_transaction_id: formData.draftFormTransId,
						},
						{
							onSuccess: async data => {
								console.log('archive draft data', data)
							},
							onError: async err => {
								console.log('Error', err)
							},
						}
					)
				}
				if (!!isOrigin) {
					if (!!accountCheckOn && accountCheckOn === accountCode.ACCOUNT_CODE) {
						let newFormInlineArray = Object.values(fields)
							.map(i => i.final)
							.filter(Boolean)
						const processId =
							formData.form_workflow_activity_type_id ||
							formData.activity_type_id
						const accountCodeParams = {
							form_id: formData.form_id,
							activity_inline_data: JSON.stringify(newFormInlineArray),
							activity_type_id: processId,
							device_os_id: 5,
						}
						mutateGroupAccountCode(
							{
								...accountCodeParams,
							},
							{
								onSuccess: async res => {
									if (!res.isError) {
										submitForm(res)
									} else {
										openDuplicate()
										setEnableSubmit(false)
										setDuplicateError(res.message)
									}
								},
								onError: async err => {
									console.log('err', err)
								},
							}
						)
					} else if (
						!!accountCheckOn &&
						accountCheckOn === accountCode.ACCOUNT_NAME
					) {
						let activityTitle
						const { data_type_id, field_id } = fieldList[0]
						activityTitle = `${formatDDMMYYYYHHMM()}-${formData.form_name}`
						if (data_type_id === 65) {
							const parseValue = fields[field_id].field_value
							activityTitle = parseValue.contact_name
						} else if (data_type_id === 57) {
							const { label } = fields[field_id].field_value
							activityTitle = !!label ? label : activityTitle
						} else if (!![19, 20].includes(data_type_id)) {
							activityTitle = !!fields[field_id]
								? fields[field_id].field_value
								: activityTitle
						}
						const payload = {
							activity_title: activityTitle,
							activity_type_id: formData.form_workflow_activity_type_id,
						}
						mutateGroupAccountName(
							{
								...payload,
							},
							{
								onSuccess: async res => {
									console.log('res', res)
									if (!res.isError) {
										submitForm(res)
									} else {
										openDuplicate()
										setEnableSubmit(false)
										setDuplicateError(res.message)
									}
								},
								onError: async err => {
									console.log('err', err)
								},
							}
						)
					} else {
						submitForm()
					}
				} else {
					if (!!accountCheckOn && accountCheckOn === accountCode.ACCOUNT_CODE) {
						let newFormInlineArray = Object.values(fields)
							.map(i => i.final)
							.filter(Boolean)
						const processId =
							formData.form_workflow_activity_type_id ||
							formData.activity_type_id
						const accountCodeParams = {
							form_id: formData.form_id,
							activity_inline_data: JSON.stringify(newFormInlineArray),
							activity_type_id: processId,
							workflow_activity_id: activity.activity_id,
							device_os_id: 5,
						}

						mutateGroupAccountCode(
							{
								...accountCodeParams,
							},
							{
								onSuccess: async res => {
									console.log('res', res)
									if (!res.isError) {
										submitNonOriginForm(res)
									} else {
										openDuplicate()
										setEnableSubmit(false)
										setDuplicateError(res.message)
									}
								},
								onError: async err => {
									console.log('err', err)
								},
							}
						)
					} else if (
						!!accountCheckOn &&
						accountCheckOn === accountCode.ACCOUNT_NAME
					) {
						let activityTitle
						const { data_type_id, field_id } = fieldList[0]
						activityTitle = `${formatDDMMYYYYHHMM()}-${formData.form_name}`
						if (data_type_id === 65) {
							const parseValue = fields[field_id].field_value
							activityTitle = parseValue.contact_name
						} else if (data_type_id === 57) {
							const { label } = fields[field_id].field_value
							activityTitle = !!label ? label : activityTitle
						} else if (!![19, 20].includes(data_type_id)) {
							activityTitle = !!fields[field_id]
								? fields[field_id].field_value
								: activityTitle
						}

						const payload = {
							activity_title: activityTitle,
							activity_type_id: formData.form_workflow_activity_type_id,
						}
						mutateGroupAccountName(
							{
								...payload,
							},
							{
								onSuccess: async res => {
									console.log('res', res)
									if (!res.isError) {
										submitNonOriginForm(res)
									} else {
										openDuplicate()
										setEnableSubmit(false)
										setDuplicateError(res.message)
									}
								},
								onError: async err => {
									console.log('err', err)
								},
							}
						)
					} else {
						submitNonOriginForm()
					}
				}
			}
		}
	}
	const onShowDescription = field => {
		let { field_name, field_description } = field
		setHFieldName(field_name)
		setFieldDesc(field_description)
		onDescOpen()
	}
	let returnContent = (
		<VStack
			className={`smart-form-panel-confirm-container`}
			w='full'
			justifyContent='flex-start'
			px={4}
			height={height}
		>
			<Modal
				isOpen={isConfirm}
				onClose={onToggleConfirm}
				className={`smart-form-panel-confirm-modal ${
					isConfirm ? 'expanded' : ''
				}`}
			>
				<ModalOverlay className={`smart-form-panel-confirm-modal-overlay`} />
				<ModalContent className={`smart-form-panel-confirm-modal-content`}>
					<ModalHeader className={`smart-form-panel-confirm-modal-header`}>
						<Center>{locale['Are you sure?']}</Center>
					</ModalHeader>
					<ModalCloseButton
						className={`smart-form-panel-confirm-modal-close-button`}
					/>
					<ModalBody className={`smart-form-panel-confirm-modal-header`}>
						<Text
							className={`smart-form-panel-confirm-text-${toSnakeCase(
								'All progress will be lost ?'
							)}`}
						>
							{locale['All progress will be lost ?']}
						</Text>
					</ModalBody>
					<ModalFooter className={`smart-form-panel-confirm-footer`}>
						<Button
							className={`smart-form-panel-confirm-close-button`}
							colorScheme={localStorage.getItem('color')}
							bg='#fff'
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							variant='outline'
							mr={3}
							minW='100px'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Close',
								// })
								onToggleConfirm()
							}}
						>
							{locale['Close']}
						</Button>
						<Button
							className={`smart-form-panel-confirm-ok-button`}
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							variant='solid'
							mr={3}
							minW='100px'
							onClick={e => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Confirm Okay',
								// })
								onClose(e)
							}}
						>
							{locale['Ok']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal
				isOpen={isInValid}
				onClose={onToggleValidate}
				className={`smart-form-panel-confirm-modal ${
					isInValid ? 'expanded' : ''
				}`}
			>
				<ModalOverlay className={`smart-form-panel-confirm-modal-overlay`} />
				<ModalContent className={`smart-form-panel-confirm-modal-content`}>
					<ModalHeader className={`smart-form-panel-confirm-modal-header`}>
						<Center>{locale['Enter mandatory fields']}</Center>
					</ModalHeader>
					<ModalCloseButton
						className={`smart-form-panel-confirm-modal-close-button`}
					/>
					<ModalBody className={`smart-form-panel-confirm-modal-header`}>
						<Text
							className={`smart-form-panel-confirm-text-${toSnakeCase(
								'Required fields are Mandatory'
							)}`}
						>
							<span style={{ color: 'red' }}>*</span>{' '}
							{!!errorField && errorField} {locale['is Required']}
						</Text>
					</ModalBody>
					<ModalFooter className={`smart-form-panel-confirm-footer`}>
						<Button
							className={`smart-form-panel-confirm-close-button`}
							colorScheme={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							variant='outline'
							mr={3}
							minW='100px'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Close Validation Message',
								// })
								onToggleValidate()
							}}
						>
							{locale['Close']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal
				isOpen={isInValidSingleField}
				onClose={onToggleValidateSingleField}
				className={`smart-form-panel-confirm-modal ${
					isInValidSingleField ? 'expanded' : ''
				}`}
			>
				<ModalOverlay className={`smart-form-panel-confirm-modal-overlay`} />
				<ModalContent className={`smart-form-panel-confirm-modal-content`}>
					<ModalHeader className={`smart-form-panel-confirm-modal-header`}>
						<Center>You must fill at least one required field</Center>
					</ModalHeader>
					<ModalCloseButton
						right='7px'
						className={`smart-form-panel-confirm-modal-close-button`}
					/>
					<ModalBody className={`smart-form-panel-confirm-modal-header`}>
						<Text
							className={`smart-form-panel-confirm-text-${toSnakeCase(
								'Minimum One Required field is Mandatory'
							)}`}
						></Text>
					</ModalBody>
					<ModalFooter className={`smart-form-panel-confirm-footer`}>
						<Button
							className={`smart-form-panel-confirm-close-button`}
							colorScheme={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							variant='outline'
							mr={3}
							minW='100px'
							onClick={() => {
								onToggleValidateSingleField()
							}}
						>
							{locale['Close']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal
				isOpen={isInValidField}
				onClose={onToggleValidateField}
				className={`smart-form-panel-confirm-modal ${
					isInValidField ? 'expanded' : ''
				}`}
			>
				<ModalOverlay className={`smart-form-panel-confirm-modal-overlay`} />
				<ModalContent className={`smart-form-panel-confirm-modal-content`}>
					<ModalHeader className={`smart-form-panel-confirm-modal-header`}>
						<Center>{locale['Enter Valid Data']}</Center>
					</ModalHeader>
					<ModalCloseButton
						className={`smart-form-panel-confirm-modal-close-button`}
					/>
					<ModalBody className={`smart-form-panel-confirm-modal-header`}>
						<Text
							className={`smart-form-panel-confirm-text-${toSnakeCase(
								'Valid fields'
							)}`}
						>
							<span style={{ color: 'red' }}>*</span>{' '}
							{!!errorField && errorField} {locale['has inValid Data']}
						</Text>
					</ModalBody>
					<ModalFooter className={`smart-form-panel-confirm-footer`}>
						<Button
							className={`smart-form-panel-confirm-close-button`}
							colorScheme={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							variant='outline'
							mr={3}
							minW='100px'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Close Validation Message',
								// })
								onToggleValidateField()
							}}
						>
							{locale['Close']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<UpdateDupName
				isOpen={showUpdateField}
				onClose={onCloseUpdateField}
				duplicateError={duplicateError}
				fieldList={fieldList}
				onSave={onSave}
			/>
			<Modal
				isOpen={isDuplicate}
				onClose={onCloseDuplicate}
				className={`smart-form-panel-duplicate-modal`}
			>
				<ModalOverlay className={`duplicate-modal-overlay`} />
				<ModalContent className={`duplicate-modal-content`}>
					<ModalHeader
						className={`duplicate-modal-header-${toSnakeCase(
							`Duplicate Account`
						)}`}
					>
						{locale['Duplicate Account']}
					</ModalHeader>
					<ModalCloseButton className={`duplicate-modal-close-button`} />
					<ModalBody className={`duplicate-modal-overlay`}>
						<Text
							className={`duplicate-modal-text-${toSnakeCase(duplicateError)}`}
						>
							{duplicateError}
						</Text>
						<Text>{`\n${locale['Do you want to update ?']}`}</Text>
					</ModalBody>
					<ModalFooter className={`duplicate-modal-footer`}>
						<Button
							className={`duplicate-modal-ok-button`}
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							variant='solid'
							mr={3}
							minW='100px'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Duplicate Error Message',
								// })
								onCloseDuplicate()
								openUpdateField()
							}}
						>
							{locale['Yes']}
						</Button>
						<Button
							className={`duplicate-modal-ok-button`}
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							size='sm'
							borderRadius='sm'
							boxShadow='sm'
							variant='solid'
							mr={3}
							minW='100px'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Duplicate Error Message',
								// })
								onCloseDuplicate()
							}}
						>
							{locale['No']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isDescOpen}
				onClose={onDescClose}
			>
				<ModalOverlay />
				<ModalContent
					borderRadius='md'
					bg='white'
					minW='35vw'
					m='auto'
					h='500px'
				>
					<ModalHeader fontSize='lg' fontWeight='400'>
						{hFieldName}
					</ModalHeader>
					<ModalCloseButton zIndex='100' />
					<Divider />
					<ModalBody
						p={0}
						w='100%'
						position='relative'
						className={'field-history-container'}
					>
						<div className='field-history-body'>
							<label style={{ fontWeight: 'bold', fontSize: '16px' }}>
								{locale['Description']}:
							</label>
							<br />
							{fieldDesc}
						</div>
					</ModalBody>
				</ModalContent>
			</Modal>
			{isLoading
				? [1, 2, 3, 4].map(i => {
						return <Skeleton w='full' h='70px' key={i} />
				  })
				: null}
			{!!formData ? (
				<HStack
					my={4}
					w='full'
					p={2}
					marginY={2}
					mx={4}
					bg='secondary'
					maxH='70px'
				>
					{!formData.is_external && (
						<Icon
							className={`smart-form-panel-back-icon`}
							as={FiArrowLeftCircle}
							cursor='pointer'
							color={localStorage.getItem('color')}
							w={8}
							h={8}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Go Back',
								// })
								onToggleConfirm()
							}}
						/>
					)}
					<Text fontSize='md' fontWeight='600' textAlign='center'>
						{formData.form_name || ''}
					</Text>
				</HStack>
			) : null}

			<Box
				ref={divRef}
				style={
					authData.organization_flag_enable_grid === 1
						? isOnFly
							? { flex: 1 }
							: { display: 'flex', flexWrap: 'wrap' }
						: { flex: 1 }
				}
				maxH={
					!isOrigin
						? !showFormAction && !isBulkEdit
							? 'calc(100vh - 290px)'
							: 'calc(100vh - 360px)'
						: height
				}
				minH={
					!isOrigin
						? !showFormAction && !isBulkEdit
							? 'calc(100vh - 290px)'
							: !!formData.is_external
							? 'calc(100vh - 260px)'
							: 'calc(100vh - 360px)'
						: !!formOnFly
						? 'calc(100vh - 160px)'
						: height
				}
				w='full'
				overflowY='scroll'
				p={2}
			>
				{fieldList && fieldList.length ? (
					formData.rate_limit_enabled === 1 &&
					Number(rateLimit.current_rate) === 0 ? (
						<Text color='tomato'>
							Your form submission limit has been reached
						</Text>
					) : (
						<FormFieldList
							isSmartForm={isSmartForm}
							accountCheckOn={accountCheckOn}
							formData={formData}
							fieldList={fieldList}
							activity={activity}
							onShowAction={e => {
								setShowFormAction(e)
								if (!!e) {
									scrollToBottom()
								}
							}}
							showFormAction={showFormAction}
							formAddFly={formAddOnFly}
							isOnFly={isOnFly}
							workforceForms={workforceForms}
							isBulkEdit={isBulkEdit}
							autoPopuateList={autoPopuateList}
							refillData={refillData}
							onSaveDraft={onSaveDraft}
							skipFieldList={skipFieldList}
							refillView={refillView}
							setEnableSubmit={setEnableSubmit}
							setSmartFieldListSmart={setSmartFieldList}
							setCheckValidate={e =>
								setCheckValidate(s => {
									let i = s.findIndex(a => a.field_id === e.field_id)
									if (i > -1) {
										s[i].valid = e.valid
										return s
									} else {
										return [...s, e]
									}
								})
							}
							scrollToBottom={scrollToBottom}
							onShowDescription={onShowDescription}
							form_flag_enable_external_link={
								formData?.form_flag_enable_external_link
							}
							isFromEmail={isFromEmail}
							isOrigin={isOrigin}
						/>
					)
				) : (
					<Text>Loading...</Text>
				)}
			</Box>
			{(!!showFormAction || !!isBulkEdit) && fieldList && fieldList.length ? (
				<Box mt={2} w='full'>
					<Divider />
					<HStack w='full' justifyContent='flex-end' p={2}>
						<Button
							variant='outline'
							size='md'
							borderRadius='sm'
							colorScheme={localStorage.getItem('color')}
							//bg={localStorage.getItem('color')}
							w='140px'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Cancel',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Form',
								// 	buttonName: 'Cancel',
								// })
								onToggleConfirm()
							}}
						>
							{locale['Cancel']}
						</Button>
						<Button
							isDisabled={enableSubmit}
							variant='solid'
							size='md'
							borderRadius='sm'
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							w='140px'
							ml={1}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_FORM_MANAGEMENT,
								// 	action: 'Smart Form Panel',
								// 	label: 'Submit',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Form',
								// 	buttonName: 'Submit',
								// })
								onSave()
							}}
						>
							{locale['Submit']}
						</Button>
						{!isOrigin &&
							!isBulkEdit &&
							!refillView &&
							formData.activity_type_flag_enable_draft === 1 && (
								<Button
									isDisabled={enableSubmit}
									variant='solid'
									size='md'
									borderRadius='sm'
									colorScheme={localStorage.getItem('color')}
									bg={localStorage.getItem('color')}
									w='140px'
									ml={1}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_FORM_MANAGEMENT,
										// 	action: 'Smart Form Panel',
										// 	label: 'Save Draft',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Form',
										// 	buttonName: 'Save as Draft',
										// })
										onSaveDraft()
									}}
								>
									{locale['Save as Draft']}
								</Button>
							)}
					</HStack>
				</Box>
			) : null}
			<Box ref={formActionRef} />
		</VStack>
	)

	if (isLoading) {
		return (
			<VStack w='full' justifyContent='flex-start' px={4}>
				{[1, 2, 3, 4].map(i => {
					return (
						<Skeleton
							key={i}
							w='full'
							boxShadow='sm'
							borderRadius='sm'
							h='70px'
						/>
					)
				})}
			</VStack>
		)
	} else {
		return (
			<>
				{returnContent}
				{formOnFly ? (
					<FormAddOnFly
						formData={formDataonFly}
						openDrawer={formOnFly}
						setFormOnFly={() => setFormOnFly(false)}
					/>
				) : null}
			</>
		)
	}
}

export default SmartFormPanel
